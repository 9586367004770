<template>
<footer>
  <div id="footer">
    <div class="container">
      <div class="row">
        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
          <header><span>Cont</span>act</header>
          <br>
          <p class="para_Heading">Address</p>
          <p>RTBI, E Block, First Floor, IIT Madras Research Park, Kanagam Rd, Tharamani, Chennai, Tamil Nadu 600113.
          </p>
          <p class="para_Heading">Call us on</p>
          <p>+91 72000 98737</p>
          <p class="para_Heading">Email us</p>
          <p class="mb-1">info@plugzmart.com</p>
          <p>support@plugzmart.com</p>
        </div>
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
          <header><span>Quick</span> Links</header>
          <br>
           <router-link class="nav-link d-block" to="/" title="Plugzmart EV Charger Manufacturer">Home</router-link>
     <router-link class="nav-link d-block" to="/about" title="About Us" >About Us</router-link>
     <router-link class="nav-link d-block" to="/services" title="Our Services">Services</router-link>
 <router-link class="nav-link d-block" to="/contact-us" title="Reach Us For EV Solutions" >Contact Us </router-link>
         <a href="https://blog.plugzmart.com/" class="d-block" title="Blogs On EV Charging Solutions">Blog</a>
           <router-link class="nav-link d-block" to="/privacy-policy" title="Plugzmart Privacy Policy">Privacy Policy </router-link>
            <router-link class="nav-link d-block" to="/terms-and-conditions" title="Plugzmart Terms And Conditions">Terms and Conditions</router-link>
             <router-link class="nav-link d-block" to="/faq" title="Plugzmart Terms And Conditions">FAQ</router-link>
 </div>
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
          <header><span>Produ</span>cts</header>
          <br>
       <router-link class="nav-link d-block" to="/ac-chargers" title="AC Chargers For EV">AC Chargers</router-link>
        <router-link class="nav-link d-block" to="/dc-chargers" title="DC Chargers For EV" >DC Chargers</router-link>
          <router-link class="nav-link d-block" to="/portable-chargers" title="Portable Chargers For EV">Portable EV Chargers</router-link>
        
        <a href="https://www.facebook.com/plugzmart/" rel="noopener" target="_blank" class="pe-2" title="Plugzmart Facebook Page"
            style="font-size: 20px;">
           
            <font-awesome-icon :icon="['fab', 'facebook-f']" />

          </a>
          <a href="https://www.instagram.com/plugzmart/" rel="noopener" target="_blank" class="pe-2 brand-link" title="Plugzmart Instagram Page"
            style="font-size: 20px;">
          <font-awesome-icon :icon="['fab', 'instagram']" />
   </a>
          <a href="https://www.linkedin.com/company/plugzmart/" rel="noopener" target="_blank" class="pe-2 brand-link" title="Plugzmart Linkedin Page"
            style="font-size: 20px;">
     <font-awesome-icon :icon="['fab', 'linkedin']" />
          </a>
        </div>
        
        <div id="" class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 text-center">
                  <header><span>servi</span>ces</header>
          <br>
       <router-link class="nav-link d-block" to="/ev-charging-management-system" title="Charging Management System For EV">Charging Management System</router-link>
        <router-link class="nav-link d-block" to="/ev-charging-management-system-whitelabelling" title="Charging Management System White Labelling For EV" >Charging Management System White Labelling</router-link>
          <router-link class="nav-link d-block" to="/ocpp-api-service" title="OCPP - API as a Service For EV">OCPP - API as a Service</router-link>
                    <router-link class="nav-link d-block" to="/ocpi-api-service" title="OCPI - API as a Service For EV">OCPI - API as a Service</router-link>
        
  </div>
        <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 text-center p-0">
        
                  <img id="footer-brochure-img" src="/images/brochure.jpg" alt="Plugzmart brochure" width="100%">
                  <div style="border: 1px solid #959595;padding:10px;border-radius: 40px;">      
          <a id="" href="/images/Plugzmart Ev Charger Brochure.pdf" target="_blank" rel="noopener">
            Download Brochure
             <font-awesome-icon  :icon="['fas', 'circle-arrow-down']" />
           </a>
        </div>

        </div>
      </div>

<div style="display: flex; justify-content: center;align-items: center;margin-top:20px;">
<img style="height:150px;width:150px" src="/images/iitm-incubation-cell.png" alt="IITM Incubation Cell Logo">
  <img style="height:150px;width:150px" src="/images/rtbi.jpeg" alt="RTBI Logo">
  <img style="height:150px;width:150px" src="/images/ISO-9001-2015-logo.png" alt="ISO 9001:2015 Certification Logo">
</div>




    </div>
  </div>
  <div id="branding">
    Powered by Meras Plugins Pvt. Ltd. Copyright ©2023 Plugzmart. All Rights Reserved.
  </div>
</footer>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faCircleArrowDown   } from '@fortawesome/free-solid-svg-icons';
// library.add(faCircleArrowDown  );
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fab, fas)

export default {
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss" scoped>
/* Your styles here */

@mixin small-mobile-screen {
	@media only screen and (max-width: 360px) {
		@content;
	}
}

@mixin mobile-screen {
	@media only screen and (max-width: 576px) {
		@content;
	}
}

@mixin intermediate-mobile-and-tablet {
	@media only screen and (min-width: 577px) and (max-width: 767px) {
		@content;
	}
}

@mixin tablet-screen-portrait {
	@media only screen and (min-width: 768px) {
		@content;
	}
}

@mixin tablet-screen-landscape {
	@media only screen and (min-width: 960px) {
		@content;
	}
}

@mixin medium-laptop-screen {
	@media only screen and (max-width: 960px) {
		@content;
	}
}

@mixin medium-screen {
	@media only screen and (min-width: 992px) {
		@content;
	}
}

@mixin desktop-screen {
	@media only screen and (min-width: 1200px) {
		@content;
	}
}

@mixin large-desktop-screen {
	@media only screen and (min-width: 1400px) {
		@content;
	}
}
// ------------------------------

@font-face {
	font-family: 'Montserrat-Regular';
  src: url('/public/fonts/montserrat/Montserrat-Regular.ttf') format('truetype'); 
	font-display: swap;
}



@font-face {
	font-family: 'Montserrat-Bold';
src: url('/public/fonts/montserrat/Montserrat-Bold.ttf') format("truetype");
	font-display: swap;
}


@mixin font-light {
	font-family: 'Montserrat-Light', sans-serif !important;
}

@mixin font-regular {
	font-family: 'Montserrat-Regular', sans-serif !important;
}

@mixin font-bold {
	font-family: 'Montserrat-Bold', sans-serif !important;
}

@mixin font-extra-bold {
	font-family: 'Montserrat-ExtraBold', sans-serif !important;
}




$pure-white: #FFF;
$pure-black: #000;
$primary-color: #00b4b4;
$secondary-color: #3A3A3A;
$footer-para: #525252;



#footer {
  color: $secondary-color;
  padding: 50px 0px;

  &>.container {
    @include mobile-screen {
      padding: 0px 35px;
    }
  }

  & header {
    font-size: 1.2rem;
    @include font-bold;

    & span {
      @include font-bold;
      padding-bottom: 8px;
      border-bottom: 1px solid $secondary-color;
    }
  }

  & h5 {
    @include font-bold;
    color: $secondary-color;
    font-size: 0.85rem;
  }

  & p {
    font-size: 14px;
    margin-bottom: 4px;
    color: #959595;
  }

  & a {
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 4px;
    color: #959595;
    transition: all 0.25s !important;

    &:hover {
      color: $primary-color;
    }
  }

  &-brand-logo>img {
    display: block;
    width: 100px !important;
    padding: 10px 0px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  &-brochure-img {
    margin-bottom: 25px;
    border: 1px solid $primary-color;
  }

  &-brochure-link {
    text-decoration: none;
    transition: 0.25s linear;
    border-radius: 40px;
    padding: 12px 22px;
    color: $secondary-color !important;
    border: 1px solid #959595;

    &:hover {
      border: 1px solid $primary-color;
      background: $primary-color;
      color: $pure-white !important;
      box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%),
        0 8px 9px -5px hsl(0deg 0% 8% / 6%);
      border: none;
    }
  }
   @include font-regular 
}

.para_Heading{
  @include font-bold;
  color: #000 !important;
}

#branding {
  color: $secondary-color;
  padding: 15px;
  text-align: center;
  font-size: 14px;
}

</style>