<template>

  <div class="page">
    <div id="headerpage">
      <topheader />
    </div>
    <div class=" displayBlock_Mob" id="concept">
      <router-view />
    </div>
    <div class="hideimgmob" id="footerpage">
      <alignfooter />
    </div>
  </div>

</template>

<script>
import alignfooter from "../views/footer.vue";
import topheader from "../views/header.vue";
export default {
  components: {
    alignfooter,
    topheader,
  },
};
// export default {
//   name: 'Home',
// };
</script>

<style scoped>
/* Your styles here */
.page {
  display: grid;
  /* height: 100vh; */
  grid-template-columns: 1fr;
  grid-template-rows: 72px 1fr 200px;
  grid-template-areas:
    "headerpage headerpage headerpage"
    "concept concept concept"
    "footerpage footerpage footerpage";
}
#headerpage {
  grid-area: headerpage;
  /* height: 72px; */
  /* width: 100%;
  position: fixed;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  top: 0;
  z-index: 998 !important; */
}
#concept {
  grid-area: concept;
  /* height: calc(100vh - 72px); */
  /* display: flex; */
  align-items: center;
  justify-content: center;
}
#footerpage {
  grid-area: footerpage;
  height: 450px;
  left: 0px;
  width: 100%;
  /* background: #0c1d39; */
}
</style>