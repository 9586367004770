import { createApp } from 'vue';
import App from './App.vue';
import router from './router';



// main.js
// Import Bootstrap CSS and JS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'; // This imports Bootstrap's JavaScript

// Import Font Awesome core
// import { library } from '@fortawesome/fontawesome-svg-core';

// Import Font Awesome icon component
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
//
// Import specific icons
// import { faRocket } from '@fortawesome/free-solid-svg-icons'

// Add icons to the library
// library.add(faRocket);

// Create Vue app





const app = createApp(App);


// Register Font Awesome component globally
// app.component('font-awesome-icon', FontAwesomeIcon);
app.config.productionTip = false

// Mount the app
app.mount('#app');





createApp(App).use(router).mount('#app');

